/*
 * Client API for Omni Data Bank Core API.
 */
export default class CoreApiClient {
  /**
   * @param object {http} axios インスタンス
   */
  constructor({http}){
    this.http = http; // axios instance.
  }

  /* ======================================================= *
   * RESTful API invoke method templates.
   * ======================================================= */

  /**
   * 指定されたパスに対応する RESTful API create (HTTP POST) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API create invoker。
   */
  _create     = path => ((data, config = {}) => this.http.post(path, data, config));

  /**
   * 指定されたパスに対応する一意に更新する RESTful API update (HTTP PUT) 関数を返す。
   * @param path リソースパス
   * @return RESTful API update invoker。
   */
  _update     = path => ((id, data, config = {}) => this.http.put(`${path}/${id}`, data, config));

  /**
   * 指定されたパスに対応する RESTful API update (HTTP PUT) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API bulk-update invoker。
   */
  _bulkUpdate = path => ((data, config = {}) => this.http.put(path, data, config));

  /**
   * 指定されたパスに対応する一意に削除する RESTful API delete (HTTP DELETE) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API delete invoker。
   */
  _delete     = path => ((id, config = {}) => this.http.delete(`${path}/${id}`, config));

  /**
   * 指定されたパスに対応する RESTful API delete (HTTP DELETE) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API bulk-delete invoker。
   */
  _bulkDelete = path => ((params = {}, config = {}) => this.http.delete(path, {params, ...config}));

  /**
   * 指定されたパスに対応する RESTful API find (HTTP GET) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API find invoker。
   */
  _find       = path => ((params = {}, config = {}) => this.http.get(path, {params, ...config}));

  /**
   * 指定されたパスに対応する一意に取得する RESTful API get (HTTP GET) 関数を返す。
   * @param path リソースパス。文字列。
   * @return RESTful API get invoker。
   */
  _get        = path => (id, params = {}, config = {}) => this.http.get(`${path}/${id}`, {params, ...config});

  /**
   * 指定されたパスに対応する HTTP OPTIONS を呼び出す関数を返す。
   * @param path リソースパス。文字列。
   * @return HTTP OPTIONS invoker.
   */
  _options    = path => ((config = {}) => this.http.options(path, config));

  /* ======================================================= *
   * resources.
   * ======================================================= */

  /**
   * '/' resources api.
   */
  get index() {
    return {
      // core/src/Resource/Page/Index.php:147:    public function onGet() : ResourceObject
      find: this._find('/'),
      get: (params = {}, config = {}) => this._find('/')(params, config),
    };
  }

  /**
   * '/conversionTriggers' resources.
   */
  get conversionTriggers(){
    return {
      options: this._options('/conversionTriggers'),
      // core/src/Resource/Page/ConversionTriggers.php:43:    public function onPost(
      create: this._create('/conversionTriggers'),
      // core/src/Resource/Page/ConversionTriggers.php:86:    public function onGet(
      find: this._find('/conversionTriggers'),
      get: (conversionTriggerId, params = {}, config = {}) => this._find('/conversionTriggers')({...params, conversionTriggerId}, config),
    };
  }

  /**
   * '/observers' resources.
   */
  get observers() {
    return {
      options: this._options('/observers'),
      // core/src/Resource/Page/Observers.php:50:    public function onPost(
      create: this._create('/observers'),
      // core/src/Resource/Page/Observers.php:130:    public function onGet(
      find: this._find('/observers'),
      //get: (id, params = {}, config = {}) => this.http.get('/observers', {params: {...params, observerId: id}, ...config}),
      get: (observerId, params = {}, config = {}) => this._find('/observers')({...params, observerId}, config)
    };
  }

  /**
   * '/audiences' resources.
   */
  get audiences() {
    return {
      web: {
        // core/src/Resource/Page/Audiences/Web.php:51:    public function onGet(
        find: this._find('/audiences/web'),
      }
    };
  }

  /**
   * '/measurement' resources.
   */
  get measurement() {
    return {
      phone: {
        trackingNumber: {
          // core/src/Resource/Page/Measurement/Phone/TrackingNumber.php:66:    public function onGet(
          find: this._find('/measurement/phone/trackingNumber')
        }
      },
      web: {
        beacon: {
          // core/src/Resource/Page/Measurement/Web/Beacon.php:31:    public function onGet(
          find: this._find('/measurement/web/beacon'),
        },
        preview: {
          // core/src/Resource/Page/Measurement/Web/Pageview.php:53:    public function onGet(
          find: this._find('/measurement/web/pageview'),
        }
      },
    };
  }

  /**
   * '/conversions' resources.
   */
  get conversions() {
    return {
      phone: {
        calls: {
          // core/src/Resource/Page/Conversions/Phone/Calls.php:48:    public function onGet(
          find: this._find('/conversions/phone/calls'),
        }
      }
    };
  }

  /**
   * '/count' resources.
   */
  get count() {
    return {
      trackingSessions: {
        // core/src/Resource/Page/Count/TrackingSessions.php:28:    public function onGet(
        find: this._find('/count/trackingSessions'),
      }
    };
  }

  /**
   * '/behaviors' resources.
   */
  get behaviors() {
    return {
      phone: {
        calls: {
          // core/src/Resource/Page/Behaviors/Phone/Calls.php:52:    public function onGet(
          find: this._find('/behaviors/phone/calls'),
        }
      }
    };
  }

  /**
   * '/hooks' resources.
   */
  get hooks() {
    return {
      adsip: {
        // core/src/Resource/Page/Hooks/Adsip.php:129:    public function onPost(
        create: this._create('/hooks/adsip'),
      },
      aws: {
        sns: {
          // core/src/Resource/Page/Hooks/Aws/Sns.php:36:    public function onPost() : ResourceObject
          create: this._create('/hooks/aws/sns'),
        }
      },
      callIntelligence: {
        // core/src/Resource/Page/Hooks/CallIntelligence.php:89:    public function onPost(
        create: this._create('/hooks/callIntelligence'),
      },
      callnotes: {
        // core/src/Resource/Page/Hooks/Callnotes.php:181:    public function onPut($requestLogId)
        update: this._bulkUpdate('/hooks/callnotes'), // FIXME
        // core/src/Resource/Page/Hooks/Callnotes.php:72:    public function onPost(
        create: this._create('/hooks/callnotes'),
        recordedAudio: {
          // core/src/Resource/Page/Hooks/Callnotes/RecordedAudio.php:17:    public function onPost($uuid) : ResourceObject
          create: this._create('/hooks/callnotes/recordedAudio'),
        }
      },
      comDesign: {
        // core/src/Resource/Page/Hooks/ComDesign.php:34:    public function onPost($SessionId, $Caller, $Callee, $StartTime, $EndTime)
        create: this._create('/hooks/comDesign'),
      },
    };
  }

  /**
   * '/schemas' resources.
   */
  get schemas() {
    return {
      // core/src/Resource/Page/Schemas.php:72:    public function onGet(string $id, string $type = 'schema') : ResourceObject
      get: (id, params = {}, config = {}) => this._find('/schemas')({...params, id}, config),
    };
  };

  /**
   * '/trackingNumbers' resources.
   */
  get trackingNumbers() {
    return {
      // core/src/Resource/Page/TrackingNumbers.php:49:    public function onPost(
      //create: (data, config = {}) => this.http.post('/trackingNumbers', data, config),
      create: this._create('/trackingNumbers'),
      // core/src/Resource/Page/TrackingNumbers.php:142:    public function onGet(
      //find: (params = {}, config = {}) => this.http.get('/trackingNumbers', {params, ...config}),
      find: this._find('/trackingNumbers'),
      //get: (id, params = {}, config = {}) => this.http.get(`/trackingNumbers`, {params: {...params, trackingNumberId: id}, ...config}),
      get: (trackingNumberId, params = {}, config = {}) => this._find('/trackingNumbers')({...params, trackingNumberId}, config),
    };
  }

  /**
   * '/phone' resources.
   */
  get phone() {
    return {
      calls: {
        // core/src/Resource/Page/Phone/Calls.php:38:    public function onGet(
        find: this._find('/phone/calls'),
      }
    };
  }

  /**
   * '/trackingNumberSuppliers' resources.
   */
  get trackingNumberSuppliers() {
    return {
      //   core/src/Resource/Page/TrackingNumberSuppliers.php:32:    public function onGet(
      find: this._find('/trackingNumberSuppliers'),
      get: (trackingNumberSupplierId, params = {}, config = {}) => this._find('/trackingNumberSuppliers')({...params, trackingNumberSupplierId}, config),
    };
  }

  /**
   * '/authentications' resources.
   */
  get authentications(){
    return {
      // core/src/Resource/Page/Authentications.php:82:    public function onPost($sid, $email, $password) : ResourceObject
      create: this._create('/authentications'),
      update: this._bulkUpdate('/authentications'), // FIXME
      login: (sid, email, password, config = {}) => this._create('/authentications')({sid, email, password}, config),
      // core/src/Resource/Page/Authentications.php:123:    public function onPut($refreshToken) : ResourceObject
      reauth: (refreshToken) => this.http.put('/authentications', {refreshToken}), // deprecated
      refresh: (refreshToken, config = {}) => this._bulkUpdate('/authentications')({refreshToken}, config), // FIXME
    };
  }

  /**
   * '/me' resources
   */
  get me() {
    return {
      // core/src/Resource/Page/Me.php:35:    public function onGet() : ResourceObject
      get: (params = {}, config = {}) => this._find('/me')(params, config),
      accounts: {
        // core/src/Resource/Page/Me/Accounts.php:23:    public function onPost($label) : ResourceObject
        create: this._create('/me/accounts'),
      },
      identity: {
        // core/src/Resource/Page/Me/Identity.php:36:    public function onPut($currentPassword, $newEmail = null, $newPassword = null) : ResourceObject
        update: this._bulkUpdate('/me/identity'), // FIXME
      },
      profile: {
        // core/src/Resource/Page/Me/Profile.php:28:    public function onPut($label) : ResourceObject
        update: this._bulkUpdate('/me/profie'), // FIXME
      }
    };
  }

  /**
   * '/accounts' resources.
   */
  get accounts(){
    return{
      options: this._options('/accounts'),
      // core/src/Resource/Page/Accounts.php:151:    public function onGet($accountId = null, $relationshipTypes = null, $page = '1', $limit = '100') : ResourceObject
      find: this._find('/accounts'),
      //get: (id, params = {}, config = {}) => this.http.get(`/accounts/${encodeURIComponent(id)}`, {params}, config),
      get: (accountId, params = {}, config = {}) => this._find('/accounts')({...params, accountId}, config),
      // core/src/Resource/Page/Accounts.php:80:    public function onPost($label, $agencyId = null, $ownerId = null) : ResourceObject
      create: this._create('/accounts'),
      // core/src/Resource/Page/Accounts.php:221:    public function onPut($accountId, $label = null) : ResourceObject
      update: (accountId, data, config = {}) => this._bulkUpdate('/accounts')({...data, accountId}, config),
      belongs: {
        // core/src/Resource/Page/Accounts/Belongs.php:21:    public function onGet(int $accountId) : ResourceObject
        find: this._find('/accounts/belongs'),
        get: (accountId, data = {}, config = {}) => this._find('/accounts/belongs')({...data, accountId}, config),
        // core/src/Resource/Page/Accounts/Belongs.php:38:    public function onPut(int $accountId, array $belongs) : ResourceObject
        update: this._bulkUpdate('/accounts/belongs'), // FIXME
        // core/src/Resource/Page/Accounts/Belongs.php:74:    public function onDelete(int $accountId, array $belongs) : ResourceObject
        delete: (accountId, params = {}, config = {}) => this._bulkDelete('/accounts/belongs')({...params, accountId}, config),
      }
    };
  }

  /**
   * '/campaigns' resources.
   */
  get campaigns(){
    return{
      options: this._options('/campaigns'),
      // core/src/Resource/Page/Campaigns.php:105:    public function onGet($campaignId = null, $accountId = null, $page = '1', $limit = '100') : ResourceObject
      find: this._find('/campaigns'),
      //get: (id, params = {}, config = {}) => this.http.get(`/campaigns/${encodeURIComponent(id)}`, {params}, config),
      get: (campaignId, params = {}, config = {}) => this._find('/campaigns')({...params, campaignId}, config),
      // core/src/Resource/Page/Campaigns.php:46:    public function onPost($accountId, string $label, array $attributes = []) : ResourceObject
      create: this._create('/campaigns'),
      // core/src/Resource/Page/Campaigns.php:168:    public function onPut(
      update: (campaignId, data = {}, config = {}) => this._bulkUpdate('/campaigns')({...data, campaignId}, config),
    };
  }

  /**
   * '/users' resources.
   */
  get users() {
    return {
      options: this._options('/users'),
      // core/src/Resource/Page/Users.php:42:    public function onGet($userId = null, $sid = null, $page = 1, $limit = 100) : ResourceObject
      /* name   type
       * ------ ----
       * userId int
       * sid    int
       * page   int
       * limit  int
       */
      find: (params = {}, config = {}) => this.http.get('/users', {params, ...config}),
      // core/src/Resource/Page/Users.php:42:    public function onGet($userId = null, $sid = null, $page = 1, $limit = 100) : ResourceObject
      get: (userId, params = {}, config = {}) => this._find('/users')({...params, userId}, config),
      //get: (userId, params = {}, config = {}) => this._find(`/users/${userId}`)({...params, userId}, config),
      belongBy: {
        options: this._options('/users/belongBy'),
        // core/src/Resource/Page/Users/BelongBy.php:33:    public function onGet(int $accountId, bool $withOwner = null) : ResourceObject
        /* name      type
         * --------- ----
         * accountId int
         * withOwner bool
         */
        find: this._find('/users/belongBy'),
      },
      ownedBy: {
        options: this._options('/users/ownedBy'),
        // core/src/Resource/Page/Users/OwnedBy.php:29:    public function onGet($accountId) : ResourceObject
        /* name      type
         * --------- ----
         * accountId int
         */
        find: this._find('/users/ownedBy'),
      },
      password: {
        options: this._options('/users/password'),
        // core/src/Resource/Page/Users/Password.php:44:    public function onPost(string $sid, string $email) : ResourceObject
        /*
         * name  type
         * ----- ------
         * sid   string
         * email string
         */
        create: this._create('/users/password'),
        // core/src/Resource/Page/Users/Password.php:89:    public function onPut(string $token, string $password) : ResourceObject
        /*
         * name     type
         * -------- ------
         * token    string
         * password string
         */
        update: (token, data = {}, config = {}) => this._bulkUpdate('/users/password')({...data, token}, config),
      },
      registration: {
        options: this._options('/users/registration'),
        // core/src/Resource/Page/Users/Registration.php:47:    public function onPost(
        /*
         * name         type
         * ------------ ------
         * sid          string
         * email        string
         * belongsTo    string
         * accountLabel string
         */
        create: this._create('/users/registration'),
        // core/src/Resource/Page/Users/Registration.php:95:    public function onPut($token, $label, $password) : ResourceObject
        /*
         * name     type
         * -------- ------
         * token    string
         * label    string
         * password string
         */
        update: (token, data = {}, config = {}) => this._bulkUpdate('/users/registration')({...data, token}, config),
      }
    };
  }

  get callConversion() {
    return {
      conversionFiles: {
        options: this._options('/callConversion/conversionFiles'),
        /*
         * NOTE: Use FormData of JavaScript in params.
         *
         * name     type
         * -------- ------
         * provider string
         * csv      File
         * files    string
         */
        create: (params = {}, config = {}) => this._create('/callConversion/conversionFiles')(params, {...config, headers: {'Content-Type': 'multipart/form-data'}})
      }
    };
  }
}

/* accounts */
import {createAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state. */
export const initialState = {
  config: {
    columns: [],
    sorted: [{id: 'createdAt', desc: true}],
    pageSize: 100,
    views: {
      campaigns: {
        columns: [],
        sorted: [{id: 'campaignId'}],
        pageSize: 100,
      },
      users: {
        columns: [],
        sorted: [],
        pageSize: 100,
      }
    }
  }
};

/* redux-thunk action creators */
export const invokers = {
  options: invokeOptions(coreApi => (coreApi.accounts.options), [{src: '$', dest: jp.stringify(['http_options', '/accounts'])}]),
  find: invokeFind(coreApi => (coreApi.accounts.find)),
  get: invokeGet(coreApi => (coreApi.accounts.get)),
  create: invokeCreate(coreApi => (coreApi.accounts.create)),
  update: invokeUpdate(coreApi => (coreApi.accounts.update)),
  belongs: {
    options: invokeOptions(coreApi => (coreApi.accounts.options), [{src: '$', dest: jp.stringify(['http_options', '/accounts/belongs'])}]),
    find: invokeFind(coreApi => (coreApi.accounts.belongs.find)),
    get: invokeGet(coreApi => (coreApi.accounts.belongs.get)),
    update: invokeUpdate(coreApi => (coreApi.accounts.belongs.update)),
    delete: invokeDelete(coreApi => (coreApi.accounts.belongs.delete)),
  }
}

/* redux actions creators */
export const updatePageConfig = createAction('UPDATE_PAGE_CONFIG', (config) => ({name: 'configOfAccounts', config}));


/* action options */
const options = {
  prefix: 'ACCOUNTS'
};

/* redux action creators. */
export const actions = createActions({
  CONFIG: {
    UPDATE_PAGE_SIZE: undefined,
    UPDATE_SORTED: undefined,
    UPDATE_COLUMNS: undefined,
    VIEWS: {
      CAMPAIGNS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      },
      USERS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      }
    }
  },
}, options);

/* reducers. */
export default handleActions({
  CONFIG: {
    UPDATE_PAGE_SIZE:  (state, {payload}) => ({...state, config:{...state.config, pageSize: payload}}),
    UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state.config, sorted: payload}}),
    UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state.config, columns: payload}}),
    VIEWS: {
      CAMPAIGNS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({v: (state.v ? state.v+1 : 0), ...state, config: {...state?.config, views: {...state?.config?.views, campaigns: {...state?.config?.views?.campaigns, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, campaigns: {...state?.config?.views?.campaigns, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, campaigns: {...state?.config?.views?.campaigns, columns: payload}}}}),
      },
      USERS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, users: {...state?.config?.views?.users, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, users: {...state?.config?.views?.users, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, users: {...state?.config?.views?.users, columns: payload}}}}),
      },
    }
  }
}, initialState, options);

import {createAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state */
export const initialState = {
  config: {
    columns: [],
    sorted: [{id: 'createdAt', desc: true}],
    pageSize: 100,
    views: {
      observers: {
        columns: [],
        sorted: [{id: 'observerIdId'}],
        pageSize: 100,
      },
      trackingNumbers: {
        columns: [],
        sorted: [{id: 'trackingNumberId'}],
        pageSize: 100,
      }
    }
  }
};

/* redux-thunk action creators */
export const invokers = {
  options: invokeOptions(coreApi => (coreApi.campaigns.options), [{src: '$', dest: jp.stringify(['http_options', '/campaigns'])}]),
  find: invokeFind(coreApi => (coreApi.campaigns.find)),
  get: invokeGet(coreApi => (coreApi.campaigns.get)),
  create: invokeCreate(coreApi => (coreApi.campaigns.create)),
  update: invokeUpdate(coreApi => (coreApi.campaigns.update)),
};

/* redux actions creators */
export const updatePageConfig = createAction('UPDATE_PAGE_CONFIG', (config) => ({name: 'configOfCampaigns', config}));


/* action options */
const options = {
  prefix: 'CAMPAIGNS'
};

/* redux action creators. */
export const actions = createActions({
  CONFIG: {
    UPDATE_PAGE_SIZE: undefined,
    UPDATE_SORTED: undefined,
    UPDATE_COLUMNS: undefined,
    VIEWS: {
      OBSERVERS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      },
      TRACKING_NUMBERS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      }
    }
  },
}, options);

/* reducers. */
export default handleActions({
  CONFIG: {
    UPDATE_PAGE_SIZE:  (state, {payload}) => ({...state, config:{...state.config, pageSize: payload}}),
    UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state.config, sorted: payload}}),
    UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state.config, columns: payload}}),
    VIEWS: {
      OBSERVERS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, observers: {...state?.config?.views?.observers, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, observers: {...state?.config?.views?.observers, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, observers: {...state?.config?.views?.observers, columns: payload}}}}),
      },
      TRACKING_NUMBERS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, columns: payload}}}}),
      }
    }
  }
}, initialState, options);


import axios from 'axios';

export default (state, {payload}) => {
  let {error} = payload;
  let newState = {...state};

  if(!error){
    //return state;
    error = payload;
  }

  if(axios.isCancel(error)){
    console.info('axios canceled.', error.message);
  }else if(error.response){
    const {status,statusText, headers, data} = error.response;
    console.warn('server error.', status, statusText, headers, data);
    switch(status){
      case 401: // Unauthorized
        for(var key in newState.identity){
          newState.identity[key] = null;
        }
        newState.me = null;
        break;
      default:
        break;
    }
    // 認証エラー
    if(headers['www-authenticate']){
      const autherror = headers['www-authenticate'];
      newState.lastError = autherror.replace(/^.*error="([^"]+)".*$/, '$1');
      newState.lastErrorDescription = autherror.replace(/^.*error_description="([^"]+)".*$/, '$1');
    }
  }else if(error.request){
    console.warn('request error.', error.request);
  }else{
    console.warn('error.', error.message);
  }
  if(error.config){
    console.warn('error.config', error.config);
  }

  return newState;
};

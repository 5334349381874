//import {addLocaleData} from 'react-intl';
//import localeEn from 'react-intl/locale-data/en';
//import localeJa from 'react-intl/locale-data/ja';
//import {defineMessages} from 'react-intl';
import ja from './lang/ja.js';


if(!Intl.PluralRules){
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/ja');
}

if(!Intl.RelativeTimeFormat){
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
}

const locale = 'ja';
const timezone = 'Asia/tokyo';
const messages = {ja};

// TODO: supported locales.
export const SUPPORTED_LOCALES = [
  {name: '日本語(ja-JP)', value: 'ja-JP'},
  {name: 'English(en-US)', value: 'en-US'},
];

// TODO: supported language
export const SUPPORTED_LANGUAGES = [
  {name: '日本語(ja)', value: 'ja'},
  {name: 'English(en)', value: 'en'},
];

// TODO: supported Timezones
export const SUPPORTED_TIMEZONES = [
  {name: '東京 (JP)', value: 'Asia/Tokyo'},
  {name: 'New York (US)', value: 'America/New_York'},
  {name: 'Los Angels (US)', value: 'America/Los_Angels'},
  {name: 'Britain (UK)', value: 'Europe/London'},
  {name: 'UTC', value: 'UTC'}
];

//addLocaleData([...localeEn, ...localeJa]);

export default {
  messages: messages[locale],
  locale: locale,
  timezone: timezone
};

import jp from 'jsonpath';

export default (state, {payload}) => {
  const {response, mapping} = payload;
  const {status, statusText, data} = response;
  let newState = {...state};

  switch(status){
    case 200: // OK
      if(mapping){
        mapping.forEach(o => {
          let value = jp.value(data, o.src);
          jp.value(newState, o.dest, value);
        });
      }
      break;
    default:
      break;
  }

  return newState;
}

import {createAction} from 'redux-actions';
import moment from 'moment';
import jp from 'jsonpath';

export const updateMe = createAction('UPDATE_ME');
export const updateIdentity = createAction('UPDATE_IDENTITY');
export const isRequesting = createAction('IS_REQUESTING');
export const raiseError = createAction('RAISE_ERROR');
export const logout = createAction('LOGOUT');
export const changeSelectedCampaign = createAction('CHANGE_SELECTED_CAMPAIGN');
export const changeSelectedAccount = createAction('CHANGE_SELECTED_ACCOUNT');
export const changeSelectedTerm = createAction('CHANGE_SELECTED_TERM', (beginTimestamp, endTimestamp) => ({beginTimestamp, endTimestamp}));
export const setIntl = createAction('SET_INTL');
export const doesRequireReload = createAction('DOES_REQUIRE_RELOAD');
export const updatePageConfig = createAction('UPDATE_PAGE_CONFIG');
export const setHttpClient = createAction('SET_HTTP_CLIENT');
export const setControlledHttpClient = createAction('SET_CONTROLLED_HTTP_CLIENT');
export const initializeDone = createAction('INITIALIZE_DONE');
export const raiseErrorMessages = createAction('RAISE_ERROR_MESSAGES');
export const setTokenOnHttpClient = createAction('SET_TOKEN_ON_HTTP_CLIENT');
export const updateLocale = createAction('UPDATE_LOCALE', (locale) => ({locale}));

export const invokeHttpRequest = createAction('INVOKE_HTTP_REQUEST');

export const setServiceConsumer = createAction('SET_SERVICE_CONSUMER');

/**
 * mapping: array [{dest: 'destination.jsonpath for new state', src: 'destination.jsonpath for response.data}]
 */
export const invokeHttpSuccess = createAction('INVOKE_HTTP_SUCCESS', (response, mapping) => ({response, mapping}));

export const invokeHttpFailure = createAction('INVOKE_HTTP_FAILURE');

export const invokeHttp = (fnHttp, mapping = undefined, fnSuccess = undefined, fnFailure = undefined) => {
  return (dispatch, getState) => {
    const {http, identity} = getState();
    dispatch(isRequesting(true));
    dispatch(invokeHttpRequest());
    fnHttp()
      .then(response => {
        if(mapping){
          dispatch(invokeHttpSuccess(response, mapping));
        }
        if('function' === typeof fnSuccess){
          fnSuccess(response);
        }
      })
      .catch(error => {
        dispatch(invokeHttpFailure(error));
        const {response}= error;
        if(response){
          const {status} = response;
          switch(status){
            case 401:
              dispatch(logout());
              break;
            default:
              break;
          }
        }
        if('function' === typeof fnFailure){
          fnFailure(error);
        }
      })
      .finally(() => dispatch(isRequesting(false)));
  }
};

export const applyModifyFunction = createAction('APPLY_MODIFY_FUNCTION');

export const login = (sid, email, password, fnSuccess = undefined, fnFailure = undefined) => {
  return (dispatch, getState) => {
    console.log('--- redux state', getState());
    const {coreApi} = getState();
    const data = {sid, email, password};
    dispatch(invokeHttp(
      //() => (http.post('/authentications', data)),
      () => coreApi.authentications.login(sid, email, password),
      [],
      (response) => {
        // Web Widget
        dispatch(updateIdentity(response.data));
        dispatch(setTokenOnHttpClient(response.data));
        dispatch(loadMe(()=>{
          const {expiresIn, refreshToken} = response.data;
          dispatch(setTimer(expiresIn, refreshToken));
        }));
      }
    ));
  };
};

export const setTimer = (expiresIn, refreshToken) => {
  return (dispatch, getState) => {
    const {timeoutId} = getState();
    if(timeoutId){
      clearTimeout(timeoutId);
    }
    const tstamp = moment().valueOf();
    const tout = moment(expiresIn * 1000).add(-5, 'minutes').diff(tstamp, 'milliseconds');
    const toid = setTimeout((a) => (dispatch(reauth(a))), tout, refreshToken);
    dispatch(applyModifyFunction((state) => ({...state, timeoutId: toid})));
  };
};

export const loadMe = (fnSuccess = undefined) => {
  return (dispatch, getState) => {
    const {coreApi, identity} = getState();
    console.log('loadMe action', getState());
    const config = {headers: {Authorization: `bearer ${identity.accessToken}`}};
    dispatch(invokeHttp(
      () => (coreApi.me.get(undefined, config)),
      undefined,
      (response) => {
        dispatch(updateMe({me: response.data}));
        if('function' === typeof fnSuccess){
          fnSuccess();
        }
      }
    ));
  };
};

export const reauth = (refreshToken, fnSuccess = undefined, fnFailure = undefined) => {
  return (dispatch, getState) => {
    const {coreApi, me} = getState();
    const data = {refreshToken};

    coreApi.authentications.refresh(refreshToken).then(response => {
      dispatch(updateIdentity(response.data));
      dispatch(setTokenOnHttpClient(response.data));
      const {expiresIn, refreshToken} = response.data;
      if(!me){
        dispatch(loadMe(() => {
          dispatch(setTimer(expiresIn, refreshToken));
          if('function' === typeof fnSuccess){
            fnSuccess(response);
          }
        }));
      }else{
        dispatch(setTimer(expiresIn, refreshToken));
      }
    }).catch(error => {
      dispatch(logout());
      if('function' === typeof fnFailure){
        fnFailure(error);
      }
    });
  };
}

export const coreApiActions = {
  authentications: {
    login: (sid, email, password) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.authentications.login(sid, email, password), undefined));
      };
    },
  },
  me: {
    get: () => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.me.get(), undefined, response => {dispatch(updateMe({me: response.data}))}));
      };
    }
  },
  users: {
    password: (sid, email, fnSuccess = undefined, fnFailure = undefined) =>  {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.users.password.create({sid, email}), undefined, fnSuccess, fnFailure));
      };
    }
  },
  accounts: {
    options: (config = {}, fnSuccess = undefined, fnFailure = undefined) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        const mapping = [{src: '$', dest: jp.stringify(['http_options', '/accounts'])}];
        dispatch(invokeHttp(() => coreApi.accounts.options(config), mapping, fnSuccess, fnFailure));
      };
    },
    find: (params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.accounts.find(params, config), undefined, fnSuccess, fnFailure));
      };
    },
    get: (id, params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.accounts.get(id, params, config), undefined, fnSuccess, fnFailure));
      };
    },
    create: (data = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.accounts.create(data, config), undefined, fnSuccess, fnFailure));
      };
    },
    update: (data = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => {
      return (dispatch, getState) => {
        const {coreApi} = getState();
        dispatch(invokeHttp(() => coreApi.accounts.update(data, config), undefined, fnSuccess, fnFailure));
      };
    }
  },
  behaviors: {
    phone: {
      calls: {
        find: (params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => {
          return (dispatch, getState) => {
            const {coreApi} = getState();
            dispatch(invokeHttp(() => coreApi.behaviors.phone.calls.find(params, config), undefined, fnSuccess, fnFailure));
          };
        }
      }
    }
  }
};


/**
 * invokeHttp を使用するパターン化された HTTP OPTIONS 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @param mapping array response.data から redux-state へコピーするオブジェクト ({src:'jsonpath.of.src' dest: 'jsonpath.of.dest'}) の配列
 * @return function of the HTTP OPTIONS invoke by invokeHttp.
 */
export const invokeOptions = (fn, mapping = undefined) => (
  (config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(config)), mapping, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful create 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful create invoke by invokeHttp.
 */
export const invokeCreate = (fn) => (
  (data = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(data, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful bulk-update 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful bulk-update invoke by invokeHttp.
 */
export const invokeBulkUpdate = (fn) => (
  (data = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(data, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful update 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful update invoke by invokeHttp.
 */
export const invokeUpdate = (fn) => (
  (id, data, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(id, data, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful delete 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful delete invoke by invokeHttp.
 */
export const invokeDelete = (fn) => (
  (id, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(id, config)), undefined, fnSuccess, fnFailure));
    }
  )
);


/**
 * invokeHttp を使用するパターン化された RESTful bulk-delete 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful bulk-delete invoke by invokeHttp.
 */
export const invokeBulkDelete = (fn) => (
  (params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispatch, getState) => {
      const {coreApi} = getState();
      dispatch(invokeHttp(() => (fn(coreApi)(params, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful find 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful find invoke by invokeHttp.
 */
export const invokeFind = (fn) => (
  (params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispach, getState) => {
      const {coreApi} = getState();
      dispach(invokeHttp(() => (fn(coreApi)(params, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

/**
 * invokeHttp を使用するパターン化された RESTful get 呼出処理を行う関数を返す。
 * @param fn core-api を引数とし任意のAPIを返す関数
 * @return function of the RESTful get invoke by invokeHttp.
 */
export const invokeGet = (fn) => (
  (id, params = {}, config = {}, fnSuccess = undefined, fnFailure = undefined) => (
    (dispach, getState) => {
      const {coreApi} = getState();
      dispach(invokeHttp(() => (fn(coreApi)(id, params, config)), undefined, fnSuccess, fnFailure));
    }
  )
);

//import defaultState from '../stateDefinitions';
import {combineReducers} from 'redux';
import {handleActions} from 'redux-actions';
import IS_REQUESTING from './isRequesting';
import UPDATE_ME from './updateMe';
import UPDATE_IDENTITY from './updateIdentity';
import RAISE_ERROR from './raiseError';
import CHANGE_SELECTED_CAMPAIGN from './changeSelectedCampaign';
import CHANGE_SELECTED_ACCOUNT from './changeSelectedAccount';
import CHANGE_SELECTED_TERM from './changeSelectedTerm';
import LOGOUT from './logout';
//import SET_INTL from './setIntl';
import DOES_REQUIRE_RELOAD from './doesRequireReload';
import UPDATE_PAGE_CONFIG from './updatePageConfig';
import SET_HTTP_CLIENT from './setHttpClient';
import SET_CONTROLLED_HTTP_CLIENT from './setControlledHttpClient';
import INITIALIZE_DONE from './initializeDone';
import RAISE_ERROR_MESSAGES from './raiseErrorMessages';
import SET_TOKEN_ON_HTTP_CLIENT from './setTokenOnHttpClient';
import INVOKE_HTTP_REQUEST from './invokeHttpRequest';
import INVOKE_HTTP_SUCCESS from './invokeHttpSuccess';
import INVOKE_HTTP_FAILURE from './invokeHttpFailure';
import APPLY_MODIFY_FUNCTION from './applyModifyFunction';
import UPDATE_LOCALE from './updateLocale';
import SET_SERVICE_CONSUMER from './setServiceConsumer';

import accounts           from '../../../modules/accounts';
import users              from '../../../modules/users';
import observers          from '../../../modules/observers';
import conversionTriggers from '../../../modules/conversionTriggers';
import campaigns          from '../../../modules/campaigns';
import behaviors          from '../../../modules/behaviors';
import audiences          from '../../../modules/audiences';

const combines = {
  accounts,
  users,
  campaigns,
  conversionTriggers,
  observers,
  behaviors,
  audiences,
};

const combinedReducers = combineReducers(combines);

// combines 対象の state のみ抽出。
const combinesState = (state) => (Object.keys(combines).reduce((s, k) => ({...s, [k]: state[k]}), {}));

// 共通
const reducers = {
  UPDATE_ME,
  UPDATE_IDENTITY,
  UPDATE_PAGE_CONFIG,
  UPDATE_LOCALE,

  CHANGE_SELECTED_CAMPAIGN,
  CHANGE_SELECTED_ACCOUNT,
  CHANGE_SELECTED_TERM,

  LOGOUT,
  IS_REQUESTING,
  DOES_REQUIRE_RELOAD,
  RAISE_ERROR,
  //SET_INTL,
  SET_HTTP_CLIENT,
  SET_CONTROLLED_HTTP_CLIENT,
  SET_TOKEN_ON_HTTP_CLIENT,
  INITIALIZE_DONE,
  RAISE_ERROR_MESSAGES,

  INVOKE_HTTP_REQUEST,
  INVOKE_HTTP_SUCCESS,
  INVOKE_HTTP_FAILURE,

  APPLY_MODIFY_FUNCTION,

  SET_SERVICE_CONSUMER,
};

const commonReducers = handleActions(reducers, {});

// root reducer.
export default (state = {}, action) => {
  const istate = combinedReducers(combinesState(state), action);
  return commonReducers({...state, ...istate}, action);
};


//
// without prefix on react app.
// Adding Custom Environment Variables
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const config  = {
  CORE_API_ENDPOINT:   process.env.REACT_APP_CORE_API_ENDPOINT,
  SERVICE_CONSUMER_ID: parseInt(process.env.REACT_APP_SERVICE_CONSUMER_ID || 1),
  ZENDESK_JWT_TOKEN: process.env.REACT_APP_ZENDESK_JWT_TOKEN,
  ZENDESK_JTI: process.env.REACT_APP_ZENDESK_JTI,
  ZENDESK_URL: process.env.REACT_APP_ZENDESK_URL,
};

export default config;

/* users */
import {creacreateAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state. */
export const initialState = {
  config: {
    columns: [],
    sorted: [{id: 'createdAt', desc: true}],
    pageSize: 100
  }
};

/* redux-thunk action creators */
export const invokers = {
  options: invokeOptions((coreApi => (coreApi.users.options)), [{src: '$', dest: jp.stringify(['http_options', '/users'])}]),
  find: invokeFind(coreApi => (coreApi.users.find)),
  get: invokeGet(coreApi => (coreApi.users.get)),
  belongBy: {
    find: invokeFind(coreApi => (coreApi.users.belongBy.find)),
  },
  ownedBy: {
    find: invokeFind(coreApi => (coreApi.users.ownedBy.find)),
  },
  password: {
    options: invokeOptions(coreApi =>(coreApi.users.password.options), [{src: '$', dest: jp.stringify(['http_options', '/users/password'])}]),
    create: invokeCreate(coreApi => (coreApi.users.password.create)),
    update: invokeUpdate(coreApi => (coreApi.users.password.update)),
  },
  registration: {
    options: invokeOptions(coreApi => (coreApi.users.registration.options), [{src: '$', dest: jp.stringify(['http_options', '/users/registration'])}]),
    create: invokeCreate(coreApi => (coreApi.users.registration.create)),
    update: invokeUpdate(coreApi => (coreApi.users.registration.update)),
  }
};


/* redux action options */
const options = {
  prefix: 'USERS'
};

/* redux action creators */
export const actions = createActions({
  UPDATE_CONFIG: undefined,
  CONFIG: {
    UPDATE_PAGE_SIZE: (pageSize) => ({pageSize}),
    UPDATE_SORTED: (sorted) => ({sorted}),
    UPDATE_COLUMNS: (columns) => ({columns}),
  }
}, options);

/* reducers */
export default handleActions({
  UPDATE_CONFIG: (state, {payload}) => ({...state, config: {...state.config, ...payload}}),
  CONFIG: {
    UPDATE_PAGE_SIZE: (state, {payload: {pageSize}}) => ({...state, config: {...state.config, pageSize}}),
    UPDATE_SORTED: (state, {payload: {sorted}}) => ({...state, config: {...state.config, sorted}}),
    UPDATE_COLUMNS: (state, {payload: {columns}}) => ({...state, config: {...state.config, columns}}),
  }
}, initialState, options);

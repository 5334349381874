import {createAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state */
export const initialState = {
  config: {
    columns: [],
    sorted: [{id: 'createdAt', desc: true}],
    pageSize: 100
  }
};

/* redux-thunk action creators */
export const invokers = {
  options: invokeOptions(coreApi => (coreApi.conversionTriggers.options), [{src: '$', dest: jp.stringify(['http_options', '/conversionTriggers'])}]),
  find: invokeFind(coreApi => (coreApi.conversionTriggers.find)),
  get: invokeGet(coreApi => (coreApi.conversionTriggers.get)),
  create: invokeCreate(coreApi => (coreApi.conversionTriggers.create)),
  update: invokeUpdate(coreApi => (coreApi.conversionTriggers.update)),
};

/* redux actions creators */
export const updatePageConfig = createAction('UPDATE_PAGE_CONFIG', (config) => ({name: 'configOfConversionTriggers', config}));


/* action options */
const options = {
  prefix: 'CONVERSION_TRIGGERS'
};

/* redux action creators. */
export const actions = createActions({
  CONFIG: {
    UPDATE_PAGE_SIZE: undefined,
    UPDATE_SORTED: undefined,
    UPDATE_COLUMNS: undefined,
  },
}, options);

/* reducers. */
export default handleActions({
  CONFIG: {
    UPDATE_PAGE_SIZE:  (state, {payload}) => ({...state, config:{...state.config, pageSize: payload}}),
    UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state.config, sorted: payload}}),
    UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state.config, columns: payload}}),
  }
}, initialState, options);


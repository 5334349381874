export default (state, {payload}) => {
  if (typeof payload !== 'object') {
    const campaigns = state.me._embedded.campaigns.filter((o) => (o.campaignId === payload));
    if(campaigns.length > 0){
      payload = campaigns.shift();
    }
  }

  const {selectedCampaign: currCampaign, changeSelectedCampaignVersion: currVer} = state;
  const changeSelectedCampaignVersion = currVer + (currCampaign.campaignId !== parseInt(payload.campaignId) ? 1 : 0);

  return {
    ...state,
    selectedCampaign: payload,
    changeSelectedCampaignVersion,
  };
};

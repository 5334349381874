/* behaviors */
import {createAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state. */
export const initialState = {
  phone: {
    calls: {
      config: {
        columns: [],
        sorted: [{id: 'visitedAt', desc: true}],
        pageSize: 100
      },
      frequencies: {
        config: {
          columns: [],
          sorted: [{id: 'createdAt', desc: true}],
          pageSize: 100
        }
      }
    },
  }
};

/* redux-thunk action creators */
export const invokers = {
  phone: {
    calls: {
      find: invokeFind(coreApi => (coreApi.behaviors.phone.calls.find)),
    }
  }
};

/* action options */
const options = {
  prefix: 'BEHAVIORS'
};

/* redux action creators. */
export const actions = createActions({
  PHONE: {
    CALLS: {
      CONFIG: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      },
      FREQUENCIES: {
        CONFIG: {
          UPDATE_PAGE_SIZE: undefined,
          UPDATE_SORTED: undefined,
          UPDATE_COLUMNS: undefined,
        },
      }
    }
  }
}, options);

const setValue = (obj, path, value) => {
  jp.value(obj, path, value);
  return obj;
};

/* reducers. */
export default handleActions({
  PHONE: {
    CALLS: {
      CONFIG: {
        UPDATE_PAGE_SIZE:  (state, {payload}) => (setValue({...state}, '$.phone.calls.config.pageSize', payload)),
        UPDATE_SORTED: (state, {payload}) => (setValue({...state}, '$.phone.calls.config.sorted', payload)),
        UPDATE_COLUMNS: (state, {payload}) => (setValue({...state}, '$.phone.calls.config.columns', payload)),
      },
      FREQUENCIES: {
        CONFIG: {
          UPDATE_PAGE_SIZE:  (state, {payload}) => (setValue({...state}, '$.phone.calls.frequencies.config.pageSize', payload)),
          UPDATE_SORTED: (state, {payload}) => (setValue({...state},     '$.phone.calls.frequencies.config.sorted', payload)),
          UPDATE_COLUMNS: (state, {payload}) => (setValue({...state},    '$.phone.calls.frequencies.config.columns', payload)),
        },
      }
    }
  }
}, initialState, options);

/* audiences */
import {createAction, createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeBulkUpdate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

/* redux initial state. */
export const initialState = {
  web: {
    config: {
      columns: [],
      sorted: [{id: 'visitedAt', desc: true}],
      pageSize: 100
    }
  }
};

/* redux-thunk action creators */
export const invokers = {
  web: {
    find: invokeFind(coreApi => (coreApi.audiences.web.find)),
  }
};

/* action options */
const options = {
  prefix: 'AUDIENCES',
}

/* redux action creators. */
export const actions = createActions({
  WEB: {
    CONFIG: {
      UPDATE_PAGE_SIZE: undefined,
      UPDATE_SORTED: undefined,
      UPDATE_COLUMNS: undefined,
    }
  }
}, options);

const setValue = (obj, path, value) => {
  jp.value(obj, path, value);
  return obj;
};

/* reducers. */
export default handleActions({
  WEB: {
    CONFIG: {
      UPDATE_PAGE_SIZE: (state, {payload}) => (setValue({...state}, '$.web.config.pageSize', payload)),
      UPDATE_SORTED: (state, {payload}) => (setValue({...state}, '$.web.config.sorted', payload)),
      UPDATE_COLUMNS: (state, {payload}) => (setValue({...state}, '$.web.config.columns', payload)),
    }
  }
}, initialState, options);

// [[https://formatjs.io/guides/message-syntax/][Message Syntax — FormatJS]]
export default {
    'Lograph Inc.': '株式会社ログラフ',
    'Omni Data Bank': 'オムニデータバンク',
    /* ----- routes ----- */
    'Dashboard': 'ダッシュボード',
    'Home': 'ホーム',
    'Log': 'ログ',
    'Call Log': 'コールログ',
    'Audience Log': '訪問者ログ',
    'Logout': 'ログアウト',
    'Account New': 'アカウント作成',
    'Observer New': '観測点作成',
    'Observer View': '観測点詳細',
    'Observer List': '観測点一覧',
    'Tracking Number New': '計測番号作成',
    'Tracking Number View': '計測番号詳細',
    'Tracking Number List': '計測番号一覧',
    'Conversion Trigger New': 'コンバージョントリガー作成',
    'Conversion Trigger View': 'コンバージョントリガー詳細',
    'Conversion Trigger List': 'コンバージョントリガー一覧',
    'User New': 'ユーザー作成',
    'User View': 'ユーザー詳細',
    'User List': 'ユーザー一覧',
    'E-mail address': 'メールアドレス',
    'Password': 'パスワード',
    'Forgot password': 'パスワードを忘れた',
    'Login': 'ログイン',
    'Email and/or password are incorrect.': 'メールアドレスもしくはパスワードが間違っています。',
    'Accounts': 'アカウント',
    'Campaigns': 'キャンペーン',
    'Today': '今日',
    'Yesterday': '昨日',
    'Last 7 Days': '過去7日間',
    'Last 30 Days': '過去30日間',
    'This Month': '今月',
    'Last Month': '先月',
    'Apply': '適用',
    'Cancel': 'キャンセル',
    'Visited at': '来訪日時',
    'Called at': '入電日時',
    'Audience ID': '来訪者 ID',
    'Call ID': '入電 ID',
    'Media': '広告種別',
    'Source': '広告媒体',
    'Keyword': 'キーワード',
    'Page view': 'PV',
    'Call duration': '通話時間',
    'Caller phone number': '発信者番号',
    'Tracking phone number': '計測番号',
    'Hangup code': '通話切断理由',
    'Device type': '端末種別',
    'Caused URL': 'ランディングページ',
    'Web conversion': 'ウェブコンバージョン',
    'Call conversion': 'コールコンバージョン',
    'Stay duration': '滞在時間',
    'Previous page': '前のページ',
    'Next page': '次のページ',
    'Loading...': '読み込み中...',
    'No rows found': '指定した条件に一致するデータがありません',
    'Page': 'ページ',
    'of': '/',
    'rows': '行',
    'Please enter email address and password.': 'メールアドレスとパスワードをご入力ください。',
    'Please enter email address.': 'メールアドレスをご入力ください。',
    'A email for password reset has sent. Please check your email box.': 'パスワード再設定用のメールが送信されました。メールボックスをご確認ください。',
    'The token has expired.': 'トークンの有効期限が切れています。',
    'Expired token': 'トークンの有効期限が切れています。',
    'Unauthorized': '認証されていません。またはトークンの有効期限が切れています。',
    'Password has been reset. Please login by new password.': 'パスワードがリセットされました。 新しいパスワードでログインしてください。',
    'Password reset': 'パスワード再設定',
    'New password': '新しいパスワード',
    'Please enter new password.': '新しいパスワードを入力してください。',
    'The token is invalid.': 'トークンが正しくありません。',
    'Password has been reset.': 'パスワードが再設定されました。',
    'Web CV': 'ウェブCV',
    'Call CV': '電話CV',
    'Ended normally': '正常終了',
    'Disconnected by caller.': '発信者による切断',
    'Disconnected by receiver.': '受電者による切断',
    'Did not respond': '未応答',
    'Receiver did not respond.': '受電者は応答しませんでした。',
    'Line was busy': '話し中',
    'Receiver was talking.': '受電者は話し中でした。',
    'Did not established': '架電未確立',
    'Did not established by phone configuration.': '電話設定などにより架電が確立しませんでした。',
    'Disconnect before transfer': '転送前に切断',
    'Caller disconnected immediately after making a call.': '発信者は電話をかけた直後に切断しました。',
    'Disconnect before receive': '転送後に切断',
    'Caller disconnected while receiver response guidance was playing.': '応答ガイダンスが再生されている間に切断されました。',
    'console': 'コンソール',
    'tv device': 'テレビ',
    'tablet': 'タブレット',
    'mobile phone': '携帯電話',
    'mobile device': '携帯端末',
    'desktop': 'PC',
    'ebook reader': '電子書籍リーダー',
    'car entertainment system': 'カーナビ',
    'digital camera': 'デジカメ',
    'Recorded call': '録音通話',
    'Play': '再生',
    'Pause': '一時停止',
    'Export as CSV': 'CSV ファイルをエクスポート',
    'Refresh': '更新',
    'Row number': '行番号',
    'Recorded audio': '通話録音',
    'Configuration': '設定',
    'Columns to appear': '表示する列',
    'History': '履歴',
    '< 30 sec': '30秒未満',
    'Account Name': 'アカウント名',
    'Creation date': '作成日時',
    'Account type': 'アカウント種別',
    'Agency Id': '代理店 ID',
    'Owner Id': '所有者 ID',
    'Account Id': 'アカウント ID',
    'Account Type': 'アカウント種別',
    'Operation': '操作',
    'Account Information': 'アカウント情報',
    'Campaign List': 'キャンペーン一覧',
    'Agency': '代理店',
    'Campaign Name': 'キャンペーン名',
    'Yes': 'はい',
    'No': 'いいえ',
    'Campaign Information': 'キャンペーン情報',
    'Start date and time': '開始日時',
    'End date and time': '終了日時',
    'Measurement number list': '計測番号一覧',
    'List of observation points': '観測点一覧',
    'Accounts to display': '表示するアカウント',
    'Accounts that you own': '自分が所有しているアカウント',
    'Accounts to which you belong': '自分が所属しているアカウント',
    'Accounts to which your agency account is an agency': '自分が所属する代理店アカウントが代理店になっているアカウント',
    'Your agency account': '自分が所属する代理店アカウント',
    'Unknown error': '原因不明のエラーが発生しました',
    'Observer name': '観測点名',
    'Phone Number': '計測番号',
    'Uncertain': '不明',
    'Transfer': '転送',
    'Transfer when non-business hours.': '時間外転送先への着信転送',
    'Send a guidance': 'ガイダンス',
    'Send a guidance when non-business hours.': '時間外用音声ガイダンスの送信',
    'Voice mail': '留守電',
    'Voice mail when non-business hours.': '時間外留守電',
    'Transfer when a receiver is busy.': '通話中転送',
    'Voice mail when a receiver is busy.': '通話中留守電',
    'A caller ended call when waiting.': '待機中に切断',
    'Transfer when no one received the call.': '誰も出ないので転送',
    'Voice mail when no one received the call.': '誰も出ないので留守電',
    'Suspended IVR by the caller.': 'IVR 中断',
    'Suspended the call because no one received the call.': '着信前に切断',
    'Blocked': '着信拒否',
    'Blocked the call by anonymous.': '非通知着信拒否',
    'Blocked the call by the caller': '指定番号着信拒否',
    'Disconnected by caller or receiver.': '正常終了',
    'Account List': 'アカウント一覧',
    'Name of Measurement point': '観測点名',
    'Observers': '観測点一覧',
    'Observation point priority': '優先値',
    'Measurement session duration': '計測セッションの継続期間',
    'Observation point View': '観測点閲覧',
    'Observation channel': '観測チャネル',
    'Replacement target number': '置換対象番号',
    'Observation target device': '観測対象デバイス',
    'Component of URL to evaluate': '評価対象要素',
    'Evaluation method': '評価方法',
    'Evaluation formula': '評価式',
    'Parameter name': 'パラメータ名',
    'Kind of URL to evaluate': '評価対象 URL 種別',
    'Default': '既定',
    'List operation condition of observation point': '観測点の発動条件一覧',
    'List of  Measurement number': '計測番号一覧',
    'All': '全て',
    'PC': 'PC',
    'Mobile': 'モバイル',
    'Millisecond': '（ミリ秒）',
    'phone': '電話',
    'query': 'URLパラメータ',
    'path': 'パス',
    'domain': 'ドメイン',
    'equal': '完全一致',
    'forwardMatch': '前方一致',
    'partialMatch': '部分一致',
    'regex': '正規表現',
    'landing': 'ランディングページ',
    'referrer': 'リファラ',
    'current': '最近訪れたページ',
    'web': 'ウェブ',
    'hour': '時間',
    'hours': '時間',
    'minute': '分',
    'minutes': '分',
    'second': '秒',
    'seconds': '秒',
    'Observer label': '観測点名',
    'Reload': '更新',
    'Account Detail': 'アカウント詳細',
    'Campaign Detail': 'キャンペーン詳細',
    'Tracking channel': '計測チャネル',
    'Timezone': 'タイムゾーン',
    'Notify destinations': '通知の宛先',
    'Web': 'ウェブ',
    'Phone': '電話',
    'Target device': '対象端末',
    'Target phone number': '対象電話番号',
    'Observer Detail': '観測点詳細',
    'Trigger Condition List': '発動条件一覧',
    'Observers to display': '表示する観測点',
    'Lifetime': '有効期限',
    'New Account': '新しいアカウント',
    'Edit Account': 'アカウントの編集',
    'Account': 'アカウント',
    'Account name': 'アカウント名',
    'Agency account ID': '代理店ID',
    'Owner user ID': '所有者ID',
    'Edit': '編集',
    'Update': '変更を保存',
    'Create new': '登録',
    'Reset': 'リセット',
    'New account created.': '新しいアカウントを作成しました。',
    'Account updated.': 'アカウントを更新しました。',
    'Account could not updated.': 'アカウントを更新できませんでした。',
    'New {name} created.': '新しい {name} を作成しました。',
    '{name} updated.': '{name} を更新しました。',
    '{name} could not updated.': '{name} を更新できませんでした。',
    'Errors': 'エラー',
    'Show details': '詳細を表示',
    'Is activate': '活性化',
    'Users': 'ユーザー',
    'User name': 'ユーザー名',
    'New User': '新しいユーザー',
    'Service consumer ID': 'サービスコンシューマーID',
    'Account ID of belongs to': '所属アカウント',
    'Account name to be created newly': '新しく作成するアカウント名',
    'Activate': '活性',
    'Inactivate': '非活性',
    'Owner\'s E-mail address to be created newly': 'アカウントオーナーのメールアドレス',
    'User Registration': 'ユーザー登録(本登録)',
    'New Campaign': '新しいキャンペーン',
    'Campaign name': 'キャンペーン名',
    'Campaign attributes': 'キャンペーン属性',
    'Campaign ID': 'キャンペーンID',
    'Add': '追加',
    'Remove': '削除',
    'Move Up': '上へ',
    'Move Down': '下へ',
    'open': '開く',
    'close': '閉じる',
    'Download': 'ダウンロード',
    'Preparing to download...': 'ダウンロード準備中…',
    'conversionTriggers.label': '表示名',
    'conversionTriggers.value': 'コンバージョンの価値',
    'conversionTriggers.enableDuplicate': '計測セッション内重複可否',
    'conversionTriggers.condition': '条件',
    'conversionTriggers.externals': '外部通知設定',
    'conversionTriggers.createdAt': '作成日時',
    'beganAt': '開始日',
    'finishedAt': '終了日',
    'Number type': '番号種別',
    'Frequency 1 minute': '1分度数',
    'Frequency 3 minutes': '3分度数',
    'Charge of frequency 1 minute': '1分度数料金',
    'Charge of frequency 3 minutes': '3分度数料金',
    'Anonymous call': '非通知',
    'Conventional phone': '固定',
    'Mobile phone': '携帯',
    'Bills': '請求機能',
    'Call Frequencies': '通話度数・料金',
    'call-frequency-{label}-{begin}-{end}.csv': '通話度数・料金-{label}-{begin, date, medium}-{end, date, medium}.csv',
    'call-log-{label}-{begin}-{end}.csv': 'コールログ-{label}-{begin, date, medium}-{end, date, medium}.csv',
    'audience-log-{label}-{begin}-{end}.csv': '訪問者ログ-{label}-{begin, date, medium}-{end, date, medium}.csv',
    'tracking-sessions-{label}-{begin}-{end}.csv': "トラッキングセッション集計-{label}-{begin, date, medium}-{end, date, medium}.csv",
    'h[ hour] m[ minute] s[ second]': 'h[ 時間] m[ 分] s[ 秒]',
    'Tracking Session Log': 'トラッキングセッション集計',
    'Observer Session Count': '観測点集計',
    'Campaign Session Count': 'キャンペーン集計',
    'Count Date': '集計日',
    'Total': '合計',
    'Status': '状態',
    'Began At': '開始日時',
    'Finished At': '終了日時',
    'Account User List': '権限',
    'All observers': '観測点を指定しない',

    // accounts
    'accountView': 'アカウント詳細',
    'accountView_accountId': 'アカウントID',
    'accountView_label': 'アカウント名',
    'accountNew': 'アカウント作成',
    'accountNew_accountLabel': 'アカウント名',
    'accountNew_email': '所有者email',
    // campaigns
    'campaigns.sattus': '状態',
    'campaigns.status.running': '稼動中',
    'campaigns.status.stopped': '停止中',

    // http status
    'http.status.504': 'サーバーの処理に時間がかかり、正常に処理ができませんでした。\nお手数ではございますが、条件を変更して再表示していただくか、お時間を空けて再度試してください。',
    'http.server.error': 'サーバーエラーが発生しました。({status} {statusText})',
    'http.client.error': '通信エラーが発生しました。({method} {url})',
    'http.error': '通信エラーが発生しました。({method} {url})',
    'on.error': 'エラーが発生しました。({message})',
    'on.unsupported.error': 'エラーが発生しました。({detail})',
    // messages.
    'confirm.transition.with.discard.edit': '編集内容を破棄して移動しますか？',
    'prevent.transition.when.loading': '通信中は遷移できません。',
    'Upload': 'アップロード',
    'conversionUpload': 'コンバージョンファイルアップロード',
    'conversionUpload_provider': 'プロバイダー',
    'conversionUpload_csv': 'CSV',
    'Conversion uploaded.': 'コンバージョンファイルをアップロードしました。',
    'Conversion could not uploaded.': 'コンバージョンファイルをアップロードできませんでした。',
    'Uploading...': 'アップロード中...',
    'IM Segment Names': 'セグメント情報',
};

import {createActions, handleActions} from 'redux-actions';
import {invokeOptions, invokeGet, invokeCreate, invokeFind, invokeUpdate, invokeDelete} from '../lib/redux/actions';
import jp from 'jsonpath';

export const initialState = {
  config: {
    columns: [],
    sorted: [],
    pageSize: 100,
    views: {
      triggerConditions: {
        columns: [],
        sorted: [],
        pageSize: 100,
      },
      trackingNumbers: {
        columns: [],
        sorted: [{id: 'trackingNumberId'}],
        pageSize: 100,
      }
    }
  }
};

export const invokers = {
  options: invokeOptions(coreApi => (coreApi.observers.options), [{src: '$', dest: jp.stringify(['http_options', '/accounts'])}]),
  find: invokeFind(coreApi => (coreApi.observers.find)),
  get: invokeGet(coreApi => (coreApi.observers.get)),
  create: invokeCreate(coreApi => (coreApi.observers.create)),
  update: invokeUpdate(coreApi => (coreApi.observers.update)),
  delete: invokeDelete(coreApi => (coreApi.observers.delete)),
};

const options = {

};

export const actions = createActions({
  CONFIG: {
    UPDATE_PAGE_SIZE: (pageSize) => ({pageSize}),
    UPDATE_SORTED: (sorted) => ({sorted}),
    UPDATE_COLUMNS: (columns) => ({columns}),
    VIEWS: {
      TRIGGER_CONDITIONS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      },
      TRACKING_NUMBERS: {
        UPDATE_PAGE_SIZE: undefined,
        UPDATE_SORTED: undefined,
        UPDATE_COLUMNS: undefined,
      }
    }
  }
}, options);

export default handleActions({
  CONFIG: {
    UPDATE_PAGE_SIZE: (state, {payload: {pageSize}}) => ({...state, config: {...state.config, pageSize}}),
    UPDATE_SORTED: (state, {payload: {sorted}}) => ({...state, config: {...state.config, sorted}}),
    UPDATE_COLUMNS: (state, {payload: {columns}}) => ({...state, config: {...state.config, columns}}),
    VIEWS: {
      TRIGGER_CONDITIONS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, triggerConditions: {...state?.config?.views?.triggerConditions, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, triggerConditions: {...state?.config?.views?.triggerConditions, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, triggerConditions: {...state?.config?.views?.triggerConditions, columns: payload}}}}),
      },
      TRACKING_NUMBERS: {
        UPDATE_PAGE_SIZE: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, pageSize: payload}}}}),
        UPDATE_SORTED: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, sorted: payload}}}}),
        UPDATE_COLUMNS: (state, {payload}) => ({...state, config: {...state?.config, views: {...state?.config?.views, trackingNumbers: {...state?.config?.views?.trackingNumbers, columns: payload}}}}),
      }
    }
  }
}, initialState, options);

import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Loadable from 'react-loadable';
import i18n from './lib/i18n/';
import Store from './lib/redux/';
import './App.scss';
import 'moment/locale/ja';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import config from './config';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

const PublicLayout = Loadable({
  loader: () => import('./containers/PublicLayout'),
  loading
});

toast.configure({position: 'top-center'}); // react-toastify

class App extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  
  componentDidMount() {
    const {
      SERVICE_CONSUMER_ID: serviceConsumerId,
      CORE_API_ENDPOINT: endpoint
    } = config;

    const http = axios.create({baseURL: endpoint});
    
    http.get("/serviceConsumers", {params: {serviceConsumerId}}).then(res => {
      const serviceConsumer = res?.data?._embedded?.serviceConsumers;
      if(serviceConsumer){
        this.setState({serviceConsumer})
        const {title} = serviceConsumer;
        document.title = title; // change title.
      }
    }).catch(e => {
      console.error("unknown service consumer.", e);
    });
  }
  
  render() {
    const {serviceConsumer} = this.state;
    if(!serviceConsumer){
      return null;
    }
    
    const storeParams = {serviceConsumer};
    
    return (
      <IntlProvider locale={i18n.locale} timezone={i18n.timezone} messages={i18n.messages}>
        <Store {...storeParams}>
          <HashRouter>
            <Switch>
              {/* public */}
              <Route exact path="/users/password/:token" name="Public" component={PublicLayout} />
              <Route exact path="/users/registration/:token" name="User Registration" component={PublicLayout}/>
              {/* authorized */}
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </HashRouter>
        </Store>
      </IntlProvider>
    );
  }
}


export default App;

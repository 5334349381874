import stateDefinitions from '../stateDefinitions';
//import {removeScript} from './../../../views/utils/removeScript'
export default (state, action) => {
  const {timeoutId} = state;

  if(timeoutId){
    clearTimeout(timeoutId);
    delete state.timeoutId;
  }

  return {
    ...state,
    me: stateDefinitions.me,
    identity: stateDefinitions.identity,
    selectedCampaign: stateDefinitions.selectedCampaign,
    selectedTerm: stateDefinitions.selectedTerm,
    configOfAudienceLog: stateDefinitions.configOfAudienceLog,
    configOfCallLog: stateDefinitions.configOfCallLog,
    configOfAccounts: stateDefinitions.configOfAccounts,
    configOfCampaigns: stateDefinitions.configOfCampaigns,
    configOfObservers: stateDefinitions.configOfObservers,
  };
};

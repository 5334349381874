export default (state, {payload}) => {
  const {selectedAccount: currAccount, changeSelectedAccountVersion: currVer} = state;
  const changeSelectedAccountVersion = currVer + (currAccount.accountId !== parseInt(payload.accountId) ? 1 : 0);

  return {
    ...state,
    selectedAccount: payload,
    changeSelectedAccountVersion
  }
};

export default (state, {payload: {beginTimestamp, endTimestamp}}) => {
  const {selectedTerm: {beginTimestamp: currBTS, endTimestamp: currETS}, changeSelectedTermVersion: currVer} = state;
  const changeSelectedTermVersion = currVer + ((currBTS != beginTimestamp || currETS != endTimestamp) ? 1 : 0);

  return {
    ...state,
    selectedTerm: {
      beginTimestamp,
      endTimestamp
    },
    changeSelectedTermVersion,
  };
};
